import { useEffect, useRef, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { useUnit } from 'effector-react';

import { Volume } from 'src/shared/ui/icons';
import { volumeModel } from 'src/entities/volume';

import { Interact, InteractWrapper } from './style';
import { shareButton } from "src/entities/share/model";

interface IProps {
  likes: string;
  preview: string;
  videoId: string;
}

let isUnMuted = false;
const InteractBlock = ({ videoId }: IProps) => {
  // mute part
  const muteRef = useRef<HTMLDivElement>(null);
  const isMuted = useUnit(volumeModel.$isMuted);
  const [lock, setLock] = useState(false);

  // share part
  const baseUrl = window.location.origin;

  function onVolumeClick() {
    if (isMuted) {
      volumeModel.unmute();
      if (!lock) {
        isUnMuted = true;
      }
    } else {
      volumeModel.mute();
      if (!lock) {
        isUnMuted = false;
      }
    }
  }

  function onShareClick() {
    console.log(`${baseUrl}/hot/${videoId}`)
    shareButton({
      title: "Fupp.app — the best vertical porn videos",
      text: "Tap to see more videos like this!",
      url: `${baseUrl}/hot/${videoId}`
    })
  }

  useEffect(() => {
    if (isMuted && isUnMuted) {
      setLock(true);
      // muteRef.current?.click();
      muteRef.current?.dispatchEvent(new MouseEvent('click', { bubbles: true }));
      console.log('click');

      setTimeout(() => setLock(false), 10);
    }
  }, [isMuted]);

  return (
    <InteractWrapper>
      {/*volume*/}
      <Interact>
        <div ref={muteRef} onClick={() => onVolumeClick()}>
          <If condition={isMuted}>
            <Then>
              <Volume.Off />
            </Then>
            <Else>
              <Volume.On />
            </Else>
          </If>
        </div>
      </Interact>
      {/*share*/}
      {navigator.share !== undefined &&
          <Interact onClick={() => onShareClick()}>
              <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill="white"
                        d="M12.171 1.73653C12.285 0.544029 13.53 -0.438471 14.7855 0.278529C17.3955 1.80703 19.8585 3.54853
            22.2405 5.41003C25.1805 7.73203 27.54 9.92053 29.211 11.668C29.9355 12.4285 29.868 13.63 29.175
            14.356C27.5202 16.0676 25.7699 17.6842 23.9325 19.198C21.0255 21.5785 18.015 23.8855 14.7375
            25.735C13.626 26.362 12.4215 25.645 12.207 24.5335L12.1815 24.364L11.841 19.003C9.117 19.06 6.48
            19.996 4.371 21.7375L3.9735 22.0705L3.7815 22.2265L3.411 22.5145C3.351 22.5595 3.291 22.6045 3.231
            22.6465L2.886 22.8865C2.77885 22.957 2.66981 23.0245 2.559 23.089L2.25 23.2555C0.801 23.971 0 23.338
            0 20.5C0 13.894 4.8675 8.01553 11.448 7.12453L11.8365 7.07803L12.171 1.73653ZM15.0345 3.94753L14.7465
            9.15553C14.7368 9.33356 14.6639 9.50231 14.5411 9.6315C14.4182 9.7607 14.2533 9.8419 14.076
            9.86053L12.0345 10.0735C7.437 10.6225 3.789 14.2165 3.1125 18.9145C5.36106 17.2566 8.02972 16.263
            10.815 16.0465L11.4105 16.012L13.9665 15.958C14.1609 15.9538 14.3494 16.0253 14.4921 16.1574C14.6348
            16.2895 14.7207 16.4719 14.7315 16.666L15.027 22.06C17.4315 20.5285 19.7235 18.766 22.023
            16.885C23.5081 15.6513 24.938 14.3525 26.3085 12.9925L25.9215 12.6085L25.0875 11.809C23.579
            10.3959 22.0106 9.04814 20.3865 7.76953C18.664 6.4115 16.878 5.13606 15.0345 3.94753Z"/>
              </svg>
          </Interact>}
      {/*hd*/}
      {/* <Interact>
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1.5" y="1.5" width="27" height="27" rx="6.5" stroke="white" strokeWidth="3" />
          <path
            fill="white"
            d="M13.3125 13.9434V15.3145H8.85938V13.9434H13.3125ZM9.38086 10.4688V19H7.62305V10.4688H9.38086ZM14.5664
                10.4688V19H12.8145V10.4688H14.5664ZM18.7148 19H16.8574L16.8691 17.6289H18.7148C19.1758 17.6289 19.5645
                17.5254 19.8809 17.3184C20.1973 17.1074 20.4355 16.8008 20.5957 16.3984C20.7598 15.9961 20.8418 15.5098
                20.8418 14.9395V14.5234C20.8418 14.0859 20.7949 13.7012 20.7012 13.3691C20.6113 13.0371 20.4766 12.7578
                20.2969 12.5312C20.1172 12.3047 19.8965 12.1348 19.6348 12.0215C19.373 11.9043 19.0723 11.8457 18.7324
                11.8457H16.8223V10.4688H18.7324C19.3027 10.4688 19.8242 10.5664 20.2969 10.7617C20.7734 10.9531 21.1855
                11.2285 21.5332 11.5879C21.8809 11.9473 22.1484 12.377 22.3359 12.877C22.5273 13.373 22.623 13.9258
                22.623 14.5352V14.9395C22.623 15.5449 22.5273 16.0977 22.3359 16.5977C22.1484 17.0977 21.8809 17.5273
                21.5332 17.8867C21.1895 18.2422 20.7773 18.5176 20.2969 18.7129C19.8203 18.9043 19.293 19 18.7148
                19ZM17.8535 10.4688V19H16.0957V10.4688H17.8535Z"
          />
        </svg>
      </Interact> */}
      {/*likes*/}
      {/* <InteractLikes>
        <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="white"
            d="M24.7407 1.70187C27.8839 3.53322 30.0942 7.25132 29.9968 11.5896C29.8755 17.0033 25.6609
                21.78 18.4791 26.0462C17.4142 26.6787 16.2918 27.5 15 27.5C13.7326 27.5 12.5619 26.6646 11.5209
                26.0462C4.33911 21.78 0.1245 17.0033 0.00308954 11.5896C-0.0942005 7.25132 2.11597 3.53322 5.25922
                1.70187C8.19918 -0.0110211 11.8922 -0.0202162 15 2.5077C18.1078 -0.0202162 21.8008 -0.0110211 24.7407
                1.70187ZM23.2305 4.29401C21.1399 3.07599 18.527 3.11931 16.264 5.40743C15.5702 6.10905 14.4298 6.10905
                13.736 5.40743C11.4731 3.11931 8.86003 3.07599 6.76948 4.29401C4.60284 5.55635 2.92863 8.23619 3.00234
                11.5223C3.08683 15.2903 6.06171 19.3139 13.053 23.4669C13.6648 23.8304 14.3043 24.2976 15
                24.4908C15.6957 24.2976 16.3351 23.8304 16.947 23.4669C23.9384 19.3139 26.9131 15.2903 26.9976
                11.5223C27.0714 8.23619 25.3971 5.55635 23.2305 4.29401Z"
          />
        </svg>
        <InteractText>{likes || '155.3K'}</InteractText>
      </InteractLikes> */}
      {/*favorite*/}
      {/* <Interact>
        <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="white"
            d="M14.3797 1.30155C15.103 0.059803 16.897 0.059803 17.6201 1.30155L21.8126 8.49964L29.954
                10.2626C31.3585 10.5667 31.9129 12.2728 30.9554 13.3444L25.4051 19.5561L26.2442 27.8437C26.389
                29.2734 24.9377 30.3279 23.6227 29.7484L16 26.3893L8.37724 29.7484C7.06223 30.3279 5.61092 29.2734
                5.75569 27.8437L6.5948 19.5561L1.04458 13.3444C0.0870974 12.2728 0.641452 10.5667 2.04592
                10.2626L10.1873 8.49964L14.3797 1.30155ZM16 4.48048L12.5301 10.4379C12.2651 10.8928 11.8212 11.2153
                11.3067 11.3268L4.56859 12.7858L9.16219 17.9269C9.51293 18.3193 9.68251 18.8413 9.62948 19.365L8.935
                26.2242L15.2438 23.4441C15.7256 23.2318 16.2743 23.2318 16.756 23.4441L23.065 26.2242L22.3705
                19.365C22.3174 18.8413 22.487 18.3193 22.8377 17.9269L27.4313 12.7858L20.6932 11.3268C20.1787 11.2153
                19.7348 10.8928 19.4698 10.4379L16 4.48048Z"
          />
        </svg>
      </Interact> */}
      {/*actions*/}
      {/* <Interact>
        <svg width="24" height="6" viewBox="0 0 24 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="white"
            d="M3 0.75C4.24264 0.75 5.25 1.7574 5.25 3C5.25 4.2426 4.24264 5.25 3 5.25C1.75735 5.25 0.75
                4.2426 0.75 3C0.75 1.7574 1.75735 0.75 3 0.75ZM12 0.75C13.2426 0.75 14.25 1.7574 14.25 3C14.25
                4.2426 13.2426 5.25 12 5.25C10.7574 5.25 9.75 4.2426 9.75 3C9.75 1.7574 10.7574 0.75 12 0.75ZM21
                0.75C22.2426 0.75 23.25 1.7574 23.25 3C23.25 4.2426 22.2426 5.25 21 5.25C19.7574 5.25 18.75 4.2426
                18.75 3C18.75 1.7574 19.7574 0.75 21 0.75Z"
          />
        </svg>
      </Interact> */}
    </InteractWrapper>
  );
};

export default InteractBlock;

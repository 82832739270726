export interface IAdsConfig {
  position: number;
  id: string;
  network: 'adsterra' | 'exoclick';
}

export const adsConfig = [
  {
    position: 3,
    id: '4952964',
    network: 'exoclick',
  },
  {
    position: 7,
    id: '4952968',
    network: 'exoclick',
  },
  {
    position: 11,
    id: '4952970',
    network: 'exoclick',
  },
  {
    position: 15,
    id: '4952972',
    network: 'exoclick',
  },
  {
    position: 19,
    id: '4952974',
    network: 'exoclick',
  },
  {
    position: 23,
    id: '4953318',
    network: 'exoclick',
  },
  {
    position: 27,
    id: '4953320',
    network: 'exoclick',
  },
  {
    position: 31,
    id: '4953322',
    network: 'exoclick',
  },
  {
    position: 35,
    id: '4953488',
    network: 'exoclick',
  },
  {
    position: 39,
    id: '4953490',
    network: 'exoclick',
  },
  {
    position: 43,
    id: '4953612',
    network: 'exoclick',
  },
  {
    position: 47,
    id: '4953614',
    network: 'exoclick',
  },
  {
    position: 51,
    id: '4953616',
    network: 'exoclick',
  },
  {
    position: 55,
    id: '4953618',
    network: 'exoclick',
  },
  {
    position: 59,
    id: '4953620',
    network: 'exoclick',
  },
  {
    position: 63,
    id: '4961630',
    network: 'exoclick',
  },
  {
    position: 67,
    id: '4961632',
    network: 'exoclick',
  },
  {
    position: 71,
    id: '4961636',
    network: 'exoclick',
  },
  {
    position: 75,
    id: '4961638',
    network: 'exoclick',
  },
  {
    position: 79,
    id: '4961640',
    network: 'exoclick',
  },
  {
    position: 83,
    id: '4961642',
    network: 'exoclick',
  },
  {
    position: 87,
    id: '4961644',
    network: 'exoclick',
  },
  {
    position: 91,
    id: '4961646',
    network: 'exoclick',
  },
  {
    position: 95,
    id: '4961648',
    network: 'exoclick',
  },
  {
    position: 99,
    id: '4961650',
    network: 'exoclick',
  },
] as IAdsConfig[];

export const adsMap = new Map(adsConfig.map((item) => [item.position, { id: item.id, network: item.network }]));

export const shareButton = (params: ShareData) => {
  if (navigator.share === undefined) {
    console.error("error: Sharing is not available");
    return;
  }
  if (!params.title) {
    console.error("error: Title is required");
    return;
  }

  let shareData: ShareData = { text: params.title, title: params.title };
  if (params.url) {
    shareData.url = params.url;
  }

  try {
    if (navigator.canShare && navigator.canShare(shareData)) {
      navigator
        .share(shareData)
        .then(() => {})
        .catch((error) => {
          console.error("Sharing failed ..", error);
        });
    }
  } catch (error) {
    console.error("error: ", error);
  }
};
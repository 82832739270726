const On = () => {
  return (
    <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 1.59229V22.4075C18 23.6004 16.6516 24.2943 15.6808 23.6009L6.90604 17.3332H3.33334C1.86058 17.3332 0.666672 16.1393 0.666672 14.6665V9.33321C0.666672 7.86045 1.86058 6.66654 3.33334 6.66654H6.90604L15.6808 0.398823C16.6516 -0.294563 18 0.399357 18 1.59229ZM15.3333 3.92412L8.45602 8.83649C8.00375 9.15954 7.46184 9.33321 6.90604 9.33321H3.33334V14.6665H6.90604C7.46184 14.6665 8.00375 14.8403 8.45602 15.1632L15.3333 20.0756V3.92412ZM22.3399 5.1479C22.8309 4.5992 23.6739 4.5525 24.2225 5.04361C26.1299 6.75069 27.3333 9.23572 27.3333 12.0001C27.3333 14.7644 26.1299 17.2495 24.2225 18.9565C23.6739 19.4476 22.8309 19.4009 22.3399 18.8523C21.8487 18.3036 21.8955 17.4607 22.4441 16.9695C23.8101 15.7469 24.6667 13.9744 24.6667 12.0001C24.6667 10.0257 23.8101 8.25325 22.4441 7.03064C21.8955 6.53953 21.8487 5.6966 22.3399 5.1479ZM21.5559 8.02505C22.6449 8.99972 23.3333 10.4204 23.3333 12C23.3333 13.5797 22.6449 15.0004 21.5559 15.9751C21.0072 16.4661 20.1643 16.4195 19.6732 15.8708C19.2198 15.3642 19.2248 14.607 19.6598 14.1073L19.7775 13.988C20.3252 13.4979 20.6667 12.7897 20.6667 12C20.6667 11.2894 20.3901 10.6447 19.9355 10.1656L19.7775 10.0121C19.2288 9.52093 19.182 8.67805 19.6732 8.12934C20.1643 7.58064 21.0072 7.53394 21.5559 8.02505Z"
        fill="white"
      />
    </svg>
  );
};

const Off = () => {
  return (
    <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 1.59243V22.4076C18 23.6005 16.6516 24.2944 15.6808 23.6011L6.90604 17.3333H3.33334C1.86058 17.3333 0.666672 16.1395 0.666672 14.6667V9.33333C0.666672 7.86057 1.86058 6.66666 3.33334 6.66666H6.90604L15.6808 0.398945C16.6516 -0.294441 18 0.399479 18 1.59243ZM15.3333 3.92425L8.45602 8.83661C8.00375 9.15966 7.46184 9.33333 6.90604 9.33333H3.33334V14.6667H6.90604C7.46184 14.6667 8.00375 14.8403 8.45602 15.1633L15.3333 20.0757V3.92425ZM19.562 8.22884C20.0426 7.7482 20.799 7.71122 21.322 8.11792L21.4476 8.22884L23.3332 10.1144L25.2188 8.22885C25.7395 7.70816 26.5837 7.70816 27.1044 8.22885C27.5851 8.7095 27.6221 9.46577 27.2153 9.9889L27.1044 10.1145L25.2188 12.0001L27.1044 13.8857C27.6252 14.4064 27.6252 15.2507 27.1044 15.7713C26.6238 16.2519 25.8674 16.2889 25.3444 15.8822L25.2188 15.7713L23.3332 13.8857L21.4476 15.7713C20.9269 16.292 20.0827 16.292 19.562 15.7713C19.0814 15.2907 19.0444 14.5344 19.4511 14.0113L19.562 13.8857L21.4476 12.0001L19.562 10.1144C19.0413 9.59373 19.0413 8.74953 19.562 8.22884Z"
        fill="white"
      />
    </svg>
  );
};

export const Volume = {
  On,
  Off,
};

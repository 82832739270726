import styled from '@emotion/styled';

export const MainContainer = styled.div`
  position: absolute;

  width: 360px;
  height: 254px;

  background: #FFFFFF;
  border-radius: 18px;
  
  left: calc(-360px - 20px);
  bottom: 20px;

  @media screen and (max-width: 1500px) {
    display: none;
  }
`;

export const TipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  height: 100%;
  padding: 15px;
`;

export const Keys = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  margin: 0 auto;
`;

export const Arrows = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const Arrow = styled.div`
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  line-height: 100px;
  background: #EFEFEF;
  border-radius: 6px;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 14px;
    height: 14px;
    margin: auto;
  }
`;

export const TipText = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: black;
`;

export const TipTextBold = styled(TipText)`
  font-weight: 700;
`;

export const Button = styled.a`
  display: block;
  cursor: pointer;
  text-decoration: unset;
  color: white;

  width: 100%;
  height: 55px;
  background-color: #EB1717;
  border-radius: 13px;
  
  p {
    font-weight: 700;
    text-align: center;
  }

  &:hover {
    opacity: .8;
  }
`;

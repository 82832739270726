import styled from '@emotion/styled'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: fit-content;
  margin-top: auto;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: -10px;
  cursor: pointer !important;
`;


export const Avatar = styled.img`
  border-radius: 50%;
  height: 35px;
  width: 35px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  text-align: start;
`

export const Description = styled.h3`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  text-align: start;
`

export const Button = styled.button`
  border: none;
  border-radius: 150px;
  color: white;
  background: rgba(255, 255, 255, 0.30);
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`

import styled from '@emotion/styled';

export const BG = styled.div`
  position: absolute;
  bottom: 0;
  padding: 20px 3.5rem 20px 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1.56%, rgba(0, 0, 0, 0.6) 85.42%);
`;

export const VideoTitle = styled.h1`
  font-size: 18px;
  text-align: left;
  margin: 0;
  font-weight: 700;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

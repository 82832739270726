import * as R from 'runtypes';
import { runtypeContract } from '@farfetched/runtypes';

import { UrlDash, UrlHLS, UrlMP4 } from 'src/shared/types/url';

export const Author = R.Record({
  id: R.Number,
  login: R.String,
  name: R.String,
  avatar_url: R.String,
  onlyfans_url: R.String,
  button_text: R.String,
})

export const VideoMeta = R.Record({
  id: R.Number,
  title: R.Optional(R.String.Or(R.Null)),
  description: R.String.Or(R.Null),
  author: Author.Or(R.Null),

  m3u8_url: UrlHLS.Or(R.Null),
  mpd_url: UrlDash.Or(R.Null),

  video_url: R.Optional(UrlMP4.Or(R.Null)),
  video_url_sd: R.Optional(UrlMP4.Or(R.Null)),

  h264_video_url: R.Optional(UrlMP4.Or(R.Null)),
  h264_video_url_sd: R.Optional(UrlMP4.Or(R.Null)),

  hevc_video_url: R.Optional(UrlMP4.Or(R.Null)),
  hevc_video_url_sd: R.Optional(UrlMP4.Or(R.Null)),

  av1_video_url: R.Optional(UrlMP4.Or(R.Null)),
  av1_video_url_sd: R.Optional(UrlMP4.Or(R.Null)),

  tags: R.Array(
    R.Record({
      id: R.Number,
      name: R.String,
    }),
  ),
});

export const Feed = R.Array(VideoMeta);

export const FeedResponse = R.Record({ data: Feed });

export const feedContract = runtypeContract(FeedResponse);

export const VideoResponse = R.Record({ data: VideoMeta });

export const videoContract = runtypeContract(VideoResponse);

import { useEffect, useMemo, useState } from 'react';
import { useUnit } from 'effector-react';
import { Case, Default, If, Switch, Then } from 'react-if';
import { Keyboard, Mousewheel, Virtual } from 'swiper';
import { useNavigate, useParams } from '@remix-run/react';
import { ErrorBoundary } from '@sentry/remix';

import { UnknownError } from 'src/shared/ui/unknown-error';

import { feedModel } from 'src/entities/feed';
import { Ads } from 'src/entities/ads';

import { Video, videoModel } from 'src/features/video';
import { DesktopScrollTip } from 'src/features/desktop-scroll-tip';
import { $isScrollTip, hideScrollTip } from "src/features/desktop-scroll-tip/model";

import { MainContainer, ServiceUnavailable, SliderContainer, SwiperExtended, SwiperSlideExtended } from './style';

interface IProps {
  firstVideo?: feedModel.IVideoMeta|null;
}

export const Page = ({ firstVideo }: IProps) => {
  const [cookieState, setCookieState] = useState(false);
  const isScrollTip = useUnit($isScrollTip);

  useEffect(() => {
    setCookieState(!!isScrollTip)
  }, [isScrollTip]);

  const feed = useUnit(feedModel.$feedWithAuthors);
  const navigate = useNavigate();
  const videos = useMemo(() => firstVideo && feed.length === 0 ? [firstVideo] : feed, [feed, firstVideo]);
  const [activeVideo, setActiveVideo] = useState(videos[0]);
  const params = useParams();


  useEffect(() => {
    if (activeVideo) {
      if (activeVideo.id.toString() === params.id?.toString()) {
        return;
      }

      navigate(params.hash ? `/hash/${params.hash}/${activeVideo.id}` : `/hot/${activeVideo.id}`, {
        replace: true,
        relative: 'path'
      });
    }
  }, [activeVideo]);

  useEffect(() => {
    if (!activeVideo) {
      setActiveVideo(videos[0]);
    }
  }, [activeVideo, videos]);


  return (
    <MainContainer>
      <SliderContainer>
        <Switch>
          <Case condition={(videos?.length || 0) > 0}>
            <SwiperExtended
              direction={'vertical'}
              pagination={false}
              modules={[Keyboard, Virtual, Mousewheel]}
              keyboard={{
                enabled: true,
              }}
              virtual={{
                enabled: true,
                cache: true,
                addSlidesAfter: 2,
                addSlidesBefore: 5,
                slides: videos,
              }}
              mousewheel={true}
              preventClicks={false}
              preventClicksPropagation={false}
              onSlideChange={(swiper) => {
                if (swiper.activeIndex === videos.length - 3) {
                  feedModel.feedQuery.start();
                }
                videoModel.setActiveIndex(swiper.activeIndex);
                const item = videos[swiper.activeIndex];
                if (item.type === 'video') {
                  setActiveVideo(item);
                }
              }}
            >
              {videos?.map((item, index) => (
                <SwiperSlideExtended key={`${item.id}:${index}`}>
                  <ErrorBoundary fallback={<UnknownError/>}>
                    {item.type === 'video' ? <Video video={item} index={index}/> : <Ads position={index}/>}
                  </ErrorBoundary>
                </SwiperSlideExtended>
              ))}
            </SwiperExtended>
          </Case>

          <Default>
            <ServiceUnavailable>
              Loading...
            </ServiceUnavailable>
          </Default>
        </Switch>
      </SliderContainer>

      <If condition={!cookieState}>
        <Then>
          <DesktopScrollTip hide={hideScrollTip}/>
        </Then>
      </If>
    </MainContainer>
  );
};

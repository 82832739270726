import styled from '@emotion/styled';

export const InteractWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  bottom: 1rem;
  right: 1rem;
  width: 36px;
  height: fit-content;
  margin-top: auto;

  //padding: 0 1rem;

  @media screen and (min-width: 768px) {
    right: 2rem;
  }
`;

export const Interact = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InteractLikes = styled.div`
  width: 36px;
  height: fit-content;
`;

export const InteractText = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
`;

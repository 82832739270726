import { createDomain, sample } from 'effector';
import { createJsonQuery } from '@farfetched/core';

import type { IUrlDash, IUrlHLS, IUrlMP4, } from 'src/shared/types/url';
import { adsConfig } from 'src/shared/config/ads';
import { attachLogger } from 'src/shared/lib/log';

import { mockData } from './mock';
import { feedContract } from './contract';
import type { IFeedMeta, IVideoMeta } from './types';

export * from './contract';
export * from './types';

const feedDomain = createDomain('feed');

attachLogger(feedDomain);

export const setInit = feedDomain.createEvent('setInit');

const $isInited = feedDomain.createStore(false, { name: 'isInited' });
$isInited.on(setInit, () => true);

export const setTag = feedDomain.createEvent<string>('setTag');

export const $activeTag = feedDomain.createStore<string | null>(null, { name: 'activeTag' });
$activeTag.on(setTag, (_, hashTag) => hashTag);

export const feedQuery = createJsonQuery({
  request: {
    method: 'POST',
    url: 'https://api.fupp.app/api/feed',
    body: { source: $activeTag, fn: (params, tag) => ({
      excluded_ids: [],
      tags: tag ? [tag.replace('_', ' ').replace('-', '/')] : [],
      authors_only: false,
    }) },
  },
  response: {
    contract: feedContract,
    mapData: ({ result }) =>
      result.data.map((item) => ({
        id: item.id,
        title: item.title,
        description: item.description,
        author: item.author ? {
          id: item.author.id,
          login: item.author.login,
          name: item.author.name,
          avatarUrl: item.author.avatar_url,
          onlyfansUrl: item.author.onlyfans_url,
          buttonText: item.author.button_text,
        } : null,
        hlsUrl: item.m3u8_url,
        dashUrl: item.mpd_url,
        h264Url: item.h264_video_url || item.video_url || null,
        hevcUrl: item.hevc_video_url || null,
        av1Url: item.av1_video_url || null,
        type: 'video' as const,
        tags: item.tags,
      })),
  },
  name: 'feedQuery',
});

const authorQuery = createJsonQuery({
  request: {
    method: 'POST',
    url: 'https://api.fupp.app/api/feed',
    body: (params) => ({
      excluded_ids: [],
      tags: [],
      authors_only: true,
    }),
  },
  response: {
    contract: feedContract,
    mapData: ({ result }) =>
      result.data.map((item) => ({
        id: item.id,
        title: item.title,
        description: item.description,
        author: item.author ? {
          id: item.author.id,
          login: item.author.login,
          name: item.author.name,
          avatarUrl: item.author.avatar_url,
          onlyfansUrl: item.author.onlyfans_url,
          buttonText: item.author.button_text,
        } : null,
        hlsUrl: item.m3u8_url,
        dashUrl: item.mpd_url,
        h264Url: item.h264_video_url || item.video_url || null,
        hevcUrl: item.hevc_video_url || null,
        av1Url: item.av1_video_url || null,
        type: 'video' as const,
        tags: item.tags,
      })),
  },
  name: 'authorQuery',
});

sample({
  clock: [$isInited, $activeTag],
  target: feedQuery.start,
});

sample({
  clock: $isInited,
  target: authorQuery.start,
});


const $author = feedDomain.createStore([] as IFeedMeta[], { name: 'author' })

$author.on(authorQuery.$data, (state, data) => data ? [...state, ...data] : state)

export const setVideo = feedDomain.createEvent<IVideoMeta>('setVideo');

export const $feed = feedDomain.createStore([] as IFeedMeta[], { name: 'feed' });

$feed.on(setVideo, (state, video) => state.length === 0 ? [video] : state);

// sample({
//   source: [feedQuery.$data, authorStore] as const,
//   fn: ([oldState, authorState]) => {

//     const newState = [...oldState]


//     authorState.forEach((item, i) => {
//       const id = (i+1)*3 + i;
//       console.log(id);
      
//       const video = newState[id]
//       if(!video){
//         return
//       }
//       if(video.type === 'video' && video.author){
//         return
//       }
//       newState.splice(id, 0, item);
//     });

//     return newState
//   },
//   target: $feed,
// })

export const $feedWithAuthors = sample({
  source: [$feed, $author],
  fn: ([feed, authors]) => {
    const newState = [...feed]

    authors.forEach((item, i) => {
      const id = (i+1)*3 + i;
      console.log(id);
      
      const video = newState[id]
      if(!video){
        return
      }
      newState.splice(id, 0, item);
    });

    return newState
  }
})

$feed.on($author, (state, payload) => {
    if(payload.length === 0){
      return state
    }

    const newState = state.filter((x) => x.type === 'video' && !x.author)

    payload.forEach((item, i) => {
      const id = (i+1)*3 + i;
      console.log(id);
      
      const video = newState[id]
      if(!video){
        return
      }
      newState.splice(id, 0, item);
    });

    return newState
})

$feed.on(feedQuery.$data, (state, data) => {
  const newState = [...state, ...(data ? data : [])];

  // adsConfig.forEach((item) => {
  //   if(!newState[item.position] || newState[item.position].type === 'ads') {
  //     return;
  //   }
  //   newState.splice(item.position, 0, {
  //     id: item.id,
  //     type: 'ads',
  //     network: item.network,
  //   });
  // });

  // if (!newState.some((x) => x.type === 'ads')) {
  //   adsConfig.forEach((item) => {
  //     if(item.position >= newState.length) return;
  //     newState.splice(item.position, 0, {
  //       id: item.id,
  //       type: 'ads',
  //       network: item.network,
  //     });
  //   });
  // }

  return newState;
});


$feed.on(setTag, (feed) => feed.length >1 ? [] : feed);

$feed.on(feedQuery.$failed, (state) =>
  state.length === 0 ?
    mockData.map((item) => ({
      id: item.id,
      title: null,
      description: null,
      hlsUrl: item.m3u8_url as IUrlHLS,
      dashUrl: item.mpd_url! as IUrlDash,
      h264Url: item.video_url as IUrlMP4,
      hevcUrl: null,
      av1Url: null,
      type: 'video' as const,
      tags: item.tags,
    })) : state,
);

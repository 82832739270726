import * as R from 'runtypes';

export const Url = R.String.withBrand('Url').withConstraint((x) => x.startsWith('https://'));
export type IUrl = R.Static<typeof Url>;

export const UrlHLS = R.String.withBrand('HLS Url').withConstraint(
  (x) => x.startsWith('https://') && x.includes('.m3u8'),
);
export type IUrlHLS = R.Static<typeof UrlHLS>;

export const UrlDash = R.String.withBrand('Dash Url').withConstraint(
  (x) => x.startsWith('https://') && x.includes('.mpd'),
);
export type IUrlDash = R.Static<typeof UrlDash>;

export const UrlMP4 = R.String.withBrand('MP4 Url').withConstraint(
  (x) => x.startsWith('https://') && x.includes('.mp4'),
);
export type IUrlMP4 = R.Static<typeof UrlMP4>;

import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Link } from '@remix-run/react';

export const TagWrapper = styled.div`
  position: relative;
  display: flex;
  height: fit-content;
  margin-top: auto;
`;

export const Tags = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-height: fit-content;
  overflow: inherit;
  transition: all 0.3s;
`;

export const Tag = styled(Link, { shouldForwardProp: isPropValid })<{ $active: boolean }>`
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  border-radius: 15px;
  border: 2px solid #FFFFFF;

  opacity: ${({ $active }) => ($active ? 0.9 : 0.6)};
  
  color: #FFFFFF;
  background: transparent;
  
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  padding: 7px 12px;

  @media screen and (max-width: 700px) {
    font-size: 12px;
    padding: 5px 10px;
  }
`;

import { feedModel } from 'src/entities/feed'

import { Icon } from './icon'

import { Wrapper, Avatar, InfoWrapper, Title, Description, Button } from './style'


interface IProps {
  author: feedModel.IAuthor | null;
}

export const Author: React.FC<IProps> = ({ author }) => {

  if (!author) {
    return null;
  }

  return (
    <Wrapper onClick={() => window.open(author.onlyfansUrl, '__blanc')}>
      <Avatar src={`https://fupp-videos.s3.waw.io.cloud.ovh.net/${author.avatarUrl}`} />
      <InfoWrapper>
        <Title>
          {author.name}
        </Title>
        <Description>
          @{author.login}
        </Description>
      </InfoWrapper>
      <Button onClick={() => window.open(author.onlyfansUrl, '__blanc')}>
        <Icon/>
        {author.buttonText}
      </Button>
    </Wrapper>
  )
}

import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { useUnit } from 'effector-react';
import ReactPlayer from 'react-player';

import { SliderChildren } from 'src/shared/style/style';

import type { feedModel } from 'src/entities/feed';
import { volumeModel } from 'src/entities/volume';

import { Author } from 'src/features/author'

import { $activeIndex } from '../model';

import TagBlock from 'src/features/tag-block/ui';
import InteractBlock from 'src/features/interact-block/ui';

import { BG, VideoTitle } from './style';

interface IProps {
  video: feedModel.IVideoMeta;
  index: number;
}

export const Video = ({ video, index }: IProps) => {
  const { height, width } = useWindowSize();
  // const [source, setSource] = useState<string>(video.hlsUrl || video.h264Url || '');

  const activeIndex = useUnit($activeIndex);
  const [progress, setProgress] = useState<number>(0);
  const [isReady, setIsReady] = useState<boolean>(false);

  const isActive = activeIndex === index;
  const isNext = activeIndex + 1 === index;

  const isMuted = useUnit(volumeModel.$isMuted) || !isActive;
  useEffect(() => {
    if (isActive && isReady) {
      const timer = setTimeout(() => {
        console.log('mute by timer');
        volumeModel.mute();
        setProgress(0);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [progress, isActive, isReady]);

  if (isActive) {
    // console.log({title: video.description, id: video.id, index, url: video.hlsUrl, source});
    // console.warn('aaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    // console.log(video.hevcUrl || video.av1Url ? [
    //   video.hevcUrl ? {src: video.hevcUrl, type: 'video/mp4; codecs=hevc'} : '',
    //   video.av1Url ? {src: video.av1Url, type: 'video/mp4; codecs=av01.0.05M.08'} : '',
    //   video.h264Url ? {src: video.h264Url, type: 'video/mp4; codecs=avc1.4D401E'} : '',
    // ].filter(Boolean): video.h264Url);
  
    // console.log(video);
  }

  const tags = video?.tags || [];
  

  return (
    <SliderChildren>

      <ReactPlayer
        url={video.h264Url!}
        controls={false}
        loop={true}
        pip={false}
        volume={isMuted ? 0 : 1}
        muted={isMuted}
        playsinline={true}
        playing={isActive}
        onReady={() => {
          console.log('ready');
          setIsReady(true);
        }}
        onPlay={() => {
          console.log('play');
        }}
        onProgress={(progress) => {
          setProgress(progress.playedSeconds);
        }}
        onError={(err, d, hi, hg) => {
          console.log({ err, d, hi, hg });
          // if (err === 'hlsError') {
          //   setSource(video.h264Url || '');
          // }
          if (typeof err === 'object' && err.name === 'NotAllowedError') {
            console.log('mute by NotAllowedError');
            setTimeout(() => {
              volumeModel.mute();
            }, 10);
          }
          setIsReady(false);
        }}
        width={`${width > 700 ? 700 : width}px`}
        height={`${height}px`}
        config={{
          file: {
            attributes: {
              controls: false,
              disablePictureInPicture: true,
              playsInline: true,
              loop: true,
              // preload: isActive || isNext ? 'auto' : 'none',
            },
            hlsOptions: {
              startFragPrefetch: isNext,
            },
          },
        }}
      />
      <BG>
        <Author author={video.author}/>
        <VideoTitle>
          {video.title}
        </VideoTitle>
        <TagBlock tags={tags} />
      </BG>
      <InteractBlock likes={''} videoId={video.id.toString()} preview={''} />
    </SliderChildren>
  );
};

import { createDomain, sample } from 'effector';

import { feedModel } from 'src/entities/feed';

const domain = createDomain('video');

export const setActiveIndex = domain.createEvent<number>('setActiveIndex');

export const $activeIndex = domain.createStore<number>(0, { name: 'activeIndex' });
$activeIndex.on(setActiveIndex, (_, index) => index);

export const $activeVideo = sample({
  source: [feedModel.$feedWithAuthors, $activeIndex] as const,
  fn: ([feed, index]) => feed?.[index] || null,
});


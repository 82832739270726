export const mockData = [
  {
    height: null,
    id: 8494,
    m3u8_url:
      'https://v.redd.it/6ox6fjnhd0oa1/HLSPlaylist.m3u8?a=1681570196%2CN2JlMTg4ZjUxMDcyNjZkNzg2MGU5ZGJlNzUzYWRhNTVmZTgzOGU2M2MzYzM5N2I3NDdiNTVjNWNkZTgxMWQ3ZA%3D%3D&amp;v=1&amp;f=sd',
    mpd_url: null,
    preview: null,
    tags: [],
    uuid: 'c5a5be56-b5e7-4205-9031-471391c90f58',
    video_url: 'https://storage.googleapis.com/tikthots-video/0c898071-83cf-4bb0-9c63-0d7e47a77daa.mp4',
    width: null,
  },
  {
    height: null,
    id: 8817,
    m3u8_url:
      'https://v.redd.it/lxkh4fdatuna1/HLSPlaylist.m3u8?a=1681584377%2CMjk2ODI2N2YzYmExNDIxYmJmYWM1N2IwYjJkYjJkZjI0MzA5NDhhMTM1NjNjYjAwODVjNTQ2YzQ0MWI3MjAzMQ%3D%3D&amp;v=1&amp;f=sd',
    mpd_url:null ,
    preview: null,
    tags: [],
    uuid: '5f64fb50-2783-4d56-9e1d-9b6af758f975',
    video_url: 'https://storage.googleapis.com/tikthots-video/5ef8c08f-7e72-446a-b962-1589013bb5a7.mp4',
    width: null,
  },
  {
    height: null,
    id: 9278,
    m3u8_url:
      'https://v.redd.it/fqttjf0xmxf51/HLSPlaylist.m3u8?a=1681571723%2CYWY3ZjQxZjE3ODI1YTk2NzViODRhNTA0Y2JhZTVlOGQ3N2JiZjhjZDRiZjNkNmRlMTQ0NWY5N2E1ZTUxMGJhYQ%3D%3D&amp;v=1&amp;f=sd',
    mpd_url:null,
    preview: null,
    tags: [],
    uuid: '671dd1a7-cb47-4e66-b6b6-b0206df90245',
    video_url: 'https://storage.googleapis.com/tikthots-video/f06796f2-4c3c-4683-b370-d91e60261316.mp4',
    width: null,
  },
  {
    height: null,
    id: 8586,
    m3u8_url:
      'https://v.redd.it/cu2gn6rnfmma1/HLSPlaylist.m3u8?a=1681570213%2CYmFlY2ZkMjIyZjdjMmM2YTY4NmRiNjk3NzdjM2UzZWExNjQ0ZDdjMGM1ZDUwN2FiNTI4YTU3ZDYyNzJjYTRlMw%3D%3D&amp;v=1&amp;f=sd',
    mpd_url:null,
    preview: null,
    tags: [],
    uuid: '3e1efa78-dbb3-4afa-bc50-e1dbad27bbae',
    video_url: 'https://storage.googleapis.com/tikthots-video/2070aa52-1eee-4d0c-b630-e41721a308ad.mp4',
    width: null,
  },
  {
    height: null,
    id: 8712,
    m3u8_url:
      'https://v.redd.it/hwvwnc79m2oa1/HLSPlaylist.m3u8?a=1681570175%2CNzE0ODY1M2ZiNWQwODRjOWJkYTg5MjQzOGE1NzI0NzRiZjE2MWJjOGVjZGQwZWFkMmZiYWU3NDY0ODdjODc1Ng%3D%3D&amp;v=1&amp;f=sd',
    mpd_url:null,
    preview: null,
    tags: [],
    uuid: 'ca409ac6-e715-4b02-9eba-a4c3e334b8da',
    video_url: 'https://storage.googleapis.com/tikthots-video/4356573e-0b23-46ac-af92-da139ccf3169.mp4',
    width: null,
  },
  {
    height: null,
    id: 9249,
    m3u8_url:
      'https://v.redd.it/l52wfpv1x1g51/HLSPlaylist.m3u8?a=1681571723%2CYjRkYmM1MjI4ZTU3OWUzNTQxNDcxYTVmYmMxYTVkYWZlOTNhOGYyZDgxMTkzYzcxYzgwMWI4Y2Y5OTE2ODlkOQ%3D%3D&amp;v=1&amp;f=sd',
    mpd_url:null,
    preview: null,
    tags: [],
    uuid: '38e03199-c61b-49e4-b066-f0f76a6a5514',
    video_url: 'https://storage.googleapis.com/tikthots-video/e7f0d99d-6f06-42dc-b9c3-ac8fefb6be1d.mp4',
    width: null,
  },
  {
    height: null,
    id: 8737,
    m3u8_url:
      'https://v.redd.it/v76p9tehcvna1/HLSPlaylist.m3u8?a=1681570208%2CZTBmZTEzMDhhYmY4YWRmOTNkYWJiOTg4MGRiYzk3OTlhMTlmMzFmM2QwNzk3M2Q1MzVmNDhkNjc1ZWI0NzI5OQ%3D%3D&amp;v=1&amp;f=sd',
    mpd_url:null,
    preview: null,
    tags: [],
    uuid: 'd9c4613d-5414-4673-ba59-7016a6e48a67',
    video_url: 'https://storage.googleapis.com/tikthots-video/4aec8e0a-0eaf-4793-a7d5-4248672174b4.mp4',
    width: null,
  },
  {
    height: null,
    id: 9010,
    m3u8_url:
      'https://v.redd.it/6yz84nbdj2oa1/HLSPlaylist.m3u8?a=1681570175%2CZTQxYzhlNmQ1NWU2YzczZmJiNTllMzIxZTdkYmQ1NWU3MDhjMTc2NGFiZmY3Njg2MjdjMTBkYzE5YjhkNTUwNg%3D%3D&amp;v=1&amp;f=sd',
    mpd_url:null,
    preview: null,
    tags: [],
    uuid: 'bf9e0cb0-8d7c-40c1-9863-924b9ce1f4e3',
    video_url: 'https://storage.googleapis.com/tikthots-video/9956b002-91d7-4a01-941b-5e09a285139f.mp4',
    width: null,
  },
  {
    height: null,
    id: 9297,
    m3u8_url:
      'https://v.redd.it/jicmfsl7kao51/HLSPlaylist.m3u8?a=1681571723%2CMjM0NmQzYjYxNGFlZjBhNGU5MjlmNDYwYmU5ZTk5MWUxZDNkZWQ5ZWM2ZmM0ZjkwNDc1Mzc3ZmM1NDM0OWRlMw%3D%3D&amp;v=1&amp;f=sd',
    mpd_url:null,
    preview: null,
    tags: [],
    uuid: '8254c0a5-89fa-4e33-b00d-66f24ca3e55c',
    video_url: 'https://storage.googleapis.com/tikthots-video/f65dd88c-4f3d-4ce8-baee-b84886add451.mp4',
    width: null,
  },
  {
    height: null,
    id: 8790,
    m3u8_url:
      'https://v.redd.it/4x7x4gfwpmfa1/HLSPlaylist.m3u8?a=1681571215%2CNDdjOTMyOTg1YzRhNjU3Y2I5Njk0YzcyNDJjYjU5YmZkZTEyYTMwYTRlY2VkYmMwOTRiNWRkMjE5MDZmNmQzZg%3D%3D&amp;v=1&amp;f=sd',
    mpd_url:null,
    preview: null,
    tags: [],
    uuid: '2a153f09-20f3-4210-b0cb-152d79e67de2',
    video_url: 'https://storage.googleapis.com/tikthots-video/56c3882e-d5fe-4d7c-b3f6-ae445afc1cb8.mp4',
    width: null,
  },
  {
    height: null,
    id: 8492,
    m3u8_url:
      'https://v.redd.it/hfsotq6effna1/HLSPlaylist.m3u8?a=1681471362%2CMjYzZWFiZjUyYzdiZjVlNTYxMGQyY2E0NTIwNjAzOTRjNzFmMTdmYTdlMWI5ZGY0MzhmYTkzMzIyZDc5MjRjYQ%3D%3D&amp;v=1&amp;f=sd',
    mpd_url:null,
    preview: null,
    tags: [],
    uuid: 'e54a8a9b-30d6-4d86-abb5-037554daa004',
    video_url: 'https://storage.googleapis.com/tikthots-video/0bc1f5d0-73df-48a7-9c6b-0cd80824aa47.mp4',
    width: null,
  },
];

import { createDomain } from 'effector';

import { attachLogger } from 'src/shared/lib/log';
import { createCookiesStore } from "../../shared/lib/cookies";
import { addDays } from 'date-fns';

const domain = createDomain('desktop-scroll-tip');

attachLogger(domain);

export const showScrollTip = domain.createEvent();
export const hideScrollTip = domain.createEvent();

export const $isScrollTip = createCookiesStore('desktop_scroll', addDays(new Date(), 2));

$isScrollTip.on(showScrollTip, () => null);

$isScrollTip.on(hideScrollTip, () => addDays(new Date(), 2));

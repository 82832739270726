import { useRef } from 'react';
import { useUnit } from 'effector-react';

import { feedModel } from 'src/entities/feed';

import { Tag, Tags, TagWrapper } from './style';

interface IProps {
  tags: feedModel.ITagMeta[];
}

const TagBlock = ({ tags }: IProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const activeTag = useUnit(feedModel.$activeTag);

  return (
    <TagWrapper>
      <Tags ref={ref}>
        {tags?.map((tag) => (
          <Tag
            $active={tag.name === activeTag}
            onClick={() => feedModel.setTag(tag.name)}
            key={tag.id}
            to={`/hash/${tag.name.replace(' ', '_').replace('/', '-')}`}
          >
            #{tag.name}
          </Tag>
        ))}
      </Tags>
    </TagWrapper>
  );
};

export default TagBlock;

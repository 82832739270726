import { Arrow, Arrows, Button, Keys, MainContainer, TipText, TipTextBold, TipWrapper } from './style';

interface IProps {
  hide: () => void;
}

export const DesktopScrollTip = ({hide}: IProps) => {
  return (
    <MainContainer>
      <TipWrapper>
        <TipTextBold>How to scroll videos?</TipTextBold>

        <TipText>Use arrows on your keyboard!</TipText>

        <Keys>
          <Arrow>
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.00008 0.856404L13.6667 7.52307L0.333414 7.52307L7.00008 0.856404Z"
                    fill="black"/>
            </svg>
          </Arrow>

          <Arrows>
            <Arrow>
              <svg width="7" height="14" viewBox="0 0 7 14" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.07709e-05 7.00004L6.66675 0.333374L6.66675 13.6667L8.07709e-05 7.00004Z"
                  fill="black"
                  fillOpacity="0.1"
                />
              </svg>
            </Arrow>
            <Arrow>
              <svg width="14" height="7" viewBox="0 0 14 7" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M6.99992 7.00004L0.333252 0.333374L13.6666 0.333374L6.99992 7.00004Z"
                      fill="black"/>
              </svg>
            </Arrow>
            <Arrow>
              <svg width="7" height="14" viewBox="0 0 7 14" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.99992 7.00004L0.333252 0.333374L0.333253 13.6667L6.99992 7.00004Z"
                  fill="black"
                  fillOpacity="0.1"
                />
              </svg>
            </Arrow>
          </Arrows>
        </Keys>

        <Button onClick={hide}>
          <p>Okay, got it</p>
        </Button>
      </TipWrapper>
    </MainContainer>
  );
};

import styled from '@emotion/styled';
import { Swiper, SwiperSlide } from 'swiper/react';

export const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  margin: auto;
  padding-bottom: 56px;
  top: 0;
  max-width: 700px;
  display: flex;
  justify-content: center;
  position: relative;
  max-height: -webkit-fill-available;

  @media (display-mode: fullscreen) {
    height: calc(100vh - 40px);
    bottom: 0;
    top: auto;
    padding-bottom: 40px;
    overflow: hidden;
  }
`;

export const ServiceUnavailable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-size: 1.5rem;
  cursor: default;
`;

export const SliderContainer = styled.div`
  height: 100%;
  width: fit-content;
  overflow: hidden;
  -moz-border-radius: 0 0 24px 24px;
  -webkit-border-radius: 0 0 24px 24px;
  border-radius: 0 0 24px 24px;
`;

export const SwiperExtended = styled(Swiper)`
  width: 100%;
  height: 100%;
  -moz-border-radius: 0 0 24px 24px;
  -webkit-border-radius: 0 0 24px 24px;
  border-radius: 0 0 24px 24px;
`;

export const SwiperSlideExtended = styled(SwiperSlide)`
  text-align: center;
  font-size: 18px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  /* img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
`;

import styled from '@emotion/styled';
// import { useWindowSize } from 'react-use';

import { adsMap } from 'src/shared/config/ads';
import { FrameContainer, SliderChildren } from 'src/shared/style/style';

import { IframeExtended } from './style';

export enum AdsProvider {
  ADSTERRA = 'adsterra',
  EXOCLICK = 'exoclick',
}

export const Wrapper = styled.div<{ width: number; height: number }>`
  width: ${(props) => (props.width ? `${props.width}px` : '100vw')};
  height: ${(props) => (props.height ? `${props.height}px` : '100vh')};
  display: flex;
  align-items: center;
`;

interface IProps {
  position: number;
}

export const Ads = ({ position }: IProps) => {
  // const { height, width } = useWindowSize();
  const ads = adsMap.get(position);
  if (!ads) {
    return null;
  }

  // if (ads.network === AdsProvider.ADSTERRA) {
  //   return (
  //     <SliderChildren>
  //       <FrameContainer>
  //         <Wrapper width={width} height={height} id={ads.id} />
  //       </FrameContainer>
  //     </SliderChildren>
  //   );
  // }

  if (ads.network === AdsProvider.EXOCLICK) {
    const exoclick_url = `//a.realsrv.com/iframe.php?idzone=${ads.id}&size=300x250`;
    return (
      <SliderChildren>
        <FrameContainer>
          <IframeExtended title="Intresting content" src={exoclick_url} />
        </FrameContainer>
      </SliderChildren>
    );
  }

  return null;
};

import { createDomain } from 'effector';

const volumeDomain = createDomain('volume');

export const mute = volumeDomain.createEvent();
export const unmute = volumeDomain.createEvent();

export const $isMuted = volumeDomain.createStore(true);

$isMuted.on(mute, () => true);
$isMuted.on(unmute, () => false);
